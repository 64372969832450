import { DeflyWalletConnect } from '@blockshake/defly-connect'
import { DaffiWalletConnect } from '@daffiwallet/connect'
import { WalletProvider, useInitializeProviders, PROVIDER_ID } from '@txnlab/use-wallet';
import { PeraWalletConnect } from '@perawallet/connect'
import algosdk from 'algosdk';

export const NODE_NETWORK = 'testnet';
export const NODE_URL = 'https://testnet-api.algonode.cloud';
export const NODE_TOKEN = '';
export const NODE_PORT = 443;

export default function MyWalletProvider({ children }) {
    const walletProviders = useInitializeProviders({
    providers: [
      { id: PROVIDER_ID.PERA, clientStatic: PeraWalletConnect },
      { id: PROVIDER_ID.DEFLY, clientStatic: DeflyWalletConnect },
      { id: PROVIDER_ID.DAFFI, clientStatic: DaffiWalletConnect },
    ],
    nodeConfig: {
      network: NODE_NETWORK,
      nodeServer: NODE_URL,
      nodePort: NODE_PORT,
      nodeToken: NODE_TOKEN
    },
    algosdkStatic: algosdk,
    debug: true
  })

  return <WalletProvider value={walletProviders}>
    {children}
  </WalletProvider>
}
